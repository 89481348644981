import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useVisionplannerDetails } from '../useVisionplannerDetails.js';
import { startVPCAuthentication } from '../authFunctions.js';

import { useLoader } from './LoaderContainer.js';
import Layout from './Layout.js';
import FormActions from './FormActions.js';
import Error from './Error.js';
import PrimaryButton from './PrimaryButton.js';

const DEFAULT_TEXT = (
  <>
    <p>
      Leg op deze pagina een koppeling met Visionplanner. Nadat je op de knop hebt gedrukt, word je doorgeleid naar de inlogpagina
      van Visionplanner. Log hier in met het adviseursaccount dat hiervoor is aangemaakt in Visionplanner om het koppelen af te ronden.
    </p>
    <p>
      Het account waarmee je inlogt wordt onder water gebruikt vanuit de FR&R onboarding flow voor het aanmaken van administraties in Visionplanner.
    </p>
  </>
);

const SUCCESS_TEXT = <p>De koppeling met Visionplanner is succesvol ingesteld.</p>;
const ERROR_TEXT = <p>Er is een fout opgetreden tijdens het koppelen met Visionplanner. Als dit probleem blijft optreden, neem dan contact op met Visionplanner.</p>;

const textByStatus = {
  success: SUCCESS_TEXT,
  error: ERROR_TEXT,
};

const VisionplannerConnection = () => {
  const visionplannerDetails = useVisionplannerDetails()?.data;
  const loader = useLoader();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState(null);

  const handleConnectClick = async () => {
    try {
      loader.show();
      startVPCAuthentication({
        authorizationServerUrl: visionplannerDetails.authorizationServerUrl,
        clientId: visionplannerDetails.clientId,
      });
    } catch (error) {
      setError(error.message);
    } finally {
      loader.hide();
    }
  };

  const status = searchParams.get('status');

  return (
    <Layout title="Verbinding met Visionplanner">
      { textByStatus[status] ?? DEFAULT_TEXT }
      {error ? <Error>{error}</Error> : null}
      <FormActions>
        <PrimaryButton type="button" onClick={handleConnectClick}>{ status ? 'Opnieuw koppelen' : 'Koppelen' }</PrimaryButton>
      </FormActions>
    </Layout>
  )};

export default VisionplannerConnection;
